import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import GetAppIcon from "@material-ui/icons/GetApp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import HomeIcon from "@material-ui/icons/Home";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DoneIcon from "@material-ui/icons/Done";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddCommentOutlinedIcon from "@material-ui/icons/AddCommentOutlined";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Hidden from "@material-ui/core/Hidden";

import "whatwg-fetch"; //For IE11
import "promise-polyfill/src/polyfill"; //For IE11

import Lightbox from "./lightbox";
// import ProductList from "../data/productList.json";
// import ProductCategories from "../data/productCategories.json";
// import ProductSpecs from "../data/productSpecs.json";
import { Link as LinkRouter } from "react-router-dom";
import ImgSockets from "../images/output-sockets.png";
import ImgSolarInverter from "../images/solar-inverter.jpg";
import ImgSolarChargeController from "../images/solar-charge-controller.jpg";

import {
  withStyles,
  withTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#E44A4B",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  card: {
    width: "100%",
    maxWidth: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 5,
  },
  cardAction: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    paddingTop: 0,
    height: 25,
  },
  compareIcon: {
    marginLeft: "auto",
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    paddingRight: theme.spacing.unit * 2,
  },
  table: {
    // minWidth: 700
  },
  row: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.background.default
    // }
  },
  listItem: {
    paddingTop: 0,
  },
  head: {
    backgroundColor: theme.palette.background.default,
  },
  headCell: {
    color: "#E44A4B",
    fontSize: "0.8rem",
  },
  snackbar: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  rightIcon: {
    marginRight: theme.spacing.unit,
  },
  paper: {
    background: "#EEEEEE",
  },
  detailsTitle: {
    paddingLeft: 0,
  },
  details: {
    padding: theme.spacing.unit,
    whiteSpace: "pre-wrap",
  },
  button: {
    margin: theme.spacing.unit,
  },
});

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ProductSpec extends React.Component {
  state = {
    value: 0, // Tab Index
    productList: {},
    isLoadedList: false,
    productCategories: {},
    isLoadedCategories: false,
    productSpecs: {},
    isLoadedSpecs: false,
    productSeriesInfo: {},
    isLoadedSeriesInfo: false,
    openDetail: true,
  };

  handleClickDetail = () => {
    this.setState((state) => ({ openDetail: !state.openDetail }));
  };

  handleChange = (event, value) => {
    this.setState({ value: value });
  };

  componentWillMount() {
    var http = "https://";
    if (
      !process.env.NODE_ENV ||
      process.env.NODE_ENV === "development" ||
      window.location.protocol !== "https:"
    ) {
      http = "http://";
    }
    window
      .fetch(http + window.location.host + "/data/productList.json")
      .then((r) => r.json())
      .then((data) => {
        this.setState({ productList: data });
        this.setState({ isLoadedList: true });
      })
      .catch((error) => {
        console.log(error);
      });
    window
      .fetch(http + window.location.host + "/data/productCategories.json")
      .then((r) => r.json())
      .then((data) => {
        this.setState({ productCategories: data });
        this.setState({ isLoadedCategories: true });
      });
    window
      .fetch(http + window.location.host + "/data/productSpecs.json")
      .then((r) => r.json())
      .then((data) => {
        this.setState({ productSpecs: data });
        this.setState({ isLoadedSpecs: true });
      })
      .catch((error) => {
        console.log(error);
      });
    window
      .fetch(http + window.location.host + "/data/productSeriesInfo.json")
      .then((r) => r.json())
      .then((data) => {
        this.setState({ productSeriesInfo: data });
        this.setState({ isLoadedSeriesInfo: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderSpec = (specText) => {
    if (typeof specText === "string" && specText.includes("#App")) {
      return (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {specText.replace("#App", "")}
          </Typography>
          <Tooltip title="Adjustable by App" placement="right">
            <Chip label="App" deleteIcon={<DoneIcon />} />
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Typography style={{ whiteSpace: "pre-wrap" }}>{specText}</Typography>
      );
    }
  };

  render() {
    if (
      !this.state.isLoadedList ||
      !this.state.isLoadedCategories ||
      !this.state.isLoadedSpecs ||
      !this.state.isLoadedSeriesInfo
    ) {
      return null;
    }
    const { classes } = this.props;
    const { value } = this.state;
    const productList = this.state.productList;
    const productNum = this.props.match.params.productNum;
    const product = productList[this.props.match.params.productType].filter(
      (product) => product.link === "/" + productNum
    )[0];
    const productSpec = this.state.productSpecs[
      this.props.match.params.productType
    ].filter(
      (product) => product.Specification === productNum.toUpperCase()
    )[0];
    const productTypeUrl = this.props.match.url.substring(
      0,
      this.props.match.url.lastIndexOf("/")
    );
    if (productSpec === undefined) {
      return (
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={8} justify="center" alignItems="center">
              <Grid xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  color="textSecondary"
                  gutterBottom
                >
                  Product not found!
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Sorry, we can't find the product information. It might be an
                  old link or the product location has been moved.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <div style={{ textAlign: "right", margin: 20 }}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              component={LinkRouter}
              to={`${productTypeUrl}`}
            >
              Back to Product
            </Button>
          </div>
        </Card>
      );
    }
    const productInfo =
      this.state.productSeriesInfo[productSpec.ProductSubtype];
    var productfeatures;
    if (productInfo["features"]) {
      productfeatures = productInfo["features"].split("• ");
      productfeatures.shift();
    }
    var downloads = product.download?.split(",");

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Helmet>
            <title>
              {product.title}, {product.description}
            </title>
            <meta
              name="description"
              content={
                product.title +
                ", " +
                product.subtitle +
                ", " +
                product.description +
                " designed and manufactured by LinkChamp"
              }
            />
            <meta
              name="keywords"
              content={
                product.title +
                ", " +
                product.subtitle +
                ", " +
                product.description
              }
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={"http://" + window.location.host + this.props.match.url}
            />
            <meta
              property="og:title"
              content={
                product.title + ", " + product.subtitle + " - LinkChamp Product"
              }
            />
            <meta
              property="og:description"
              content={
                product.title +
                ", " +
                product.subtitle +
                " designed and manufactured by LinkChamp"
              }
            />
            <meta
              property="og:image"
              content={"http://" + window.location.host + product.image}
            />
          </Helmet>
          <Grid container direction="row" justify="flex-start" spacing={32}>
            <Grid item>
              <Paper className={classes.paper} elevation={0}>
                <Button
                  className={classes.button}
                  component={LinkRouter}
                  to={`${productTypeUrl}`}
                >
                  <HomeIcon className={classes.rightIcon} />
                  Product
                  <NavigateNextIcon className={classes.rightIcon} />
                  {this.props.match.params.productType}
                </Button>
                <Button className={classes.button}>
                  <NavigateNextIcon className={classes.rightIcon} />
                  {productNum}
                </Button>
              </Paper>
            </Grid>
          </Grid>
          <Card className={classes.card}>
            <Lightbox product={product} />
            <CardContent>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={9}>
                  <Typography variant="subtitle2" component="h2">
                    {product.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="h1"
                    color="textSecondary"
                  >
                    {product.subtitle}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {product.description}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Tooltip
                    title={
                      this.props.cart[product.title] !== undefined
                        ? "Remove from Inquiry"
                        : "Add to Inquiry"
                    }
                    aria-label="Add to Inquiry"
                  >
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        if (this.props.cart[product.title] !== undefined) {
                          this.props.cartCallback({
                            name: product.title,
                            moq: 0,
                          });
                        } else {
                          this.props.cartCallback({
                            name: product.title,
                            moq: 100,
                            img: product.image,
                            url: this.props.match.url,
                            spec:
                              productSpec.ProductType.includes("Converter") ||
                              productSpec.ProductType.includes("Controller")
                                ? 0
                                : 230,
                            isNewAdd: true,
                          });
                        }
                      }}
                      button="true"
                      className={classes.compareIcon}
                    >
                      {this.props.cart[product.title] !== undefined ? (
                        <CheckBoxIcon />
                      ) : (
                        <AddCommentOutlinedIcon />
                      )}
                      <Typography
                        variant="caption"
                        color="primary"
                        component="h3"
                        style={{ paddingLeft: 8 }}
                      >
                        Inquiry
                      </Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              {productInfo["description"] && (
                <>
                  <ListItem
                    button
                    onClick={this.handleClickDetail}
                    className={classes.detailsTitle}
                  >
                    <ListItemText>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="h2"
                      >
                        DESCRIPTION
                      </Typography>
                    </ListItemText>
                    {this.state.openDetail ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={this.state.openDetail}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.details}
                      component="h3"
                    >
                      {productInfo["description"]}
                    </Typography>
                  </Collapse>
                </>
              )}
              {product.subtitle.includes("Solar") && (
                <>
                  <ListItem
                    button
                    onClick={this.handleClickDetail}
                    className={classes.detailsTitle}
                  >
                    <ListItemText>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="h2"
                      >
                        APPLICATION
                      </Typography>
                    </ListItemText>
                    {this.state.openDetail ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={this.state.openDetail}
                    timeout="auto"
                    unmountOnExit
                  >
                    <img
                      src={
                        product.subtitle.includes("Inverter")
                          ? ImgSolarInverter
                          : ImgSolarChargeController
                      }
                      style={{
                        width: "100%",
                        paddingLeft: "10%",
                        paddingRight: "10%",
                      }}
                      alt={
                        product.subtitle.includes("Inverter")
                          ? "Solar Inverter Application"
                          : "Solar Charge Controller Application"
                      }
                      title={
                        product.subtitle.includes("Inverter")
                          ? "Solar Inverter Application"
                          : "Solar Charge Controller Application"
                      }
                      loading="lazy"
                    />
                  </Collapse>
                </>
              )}
            </CardContent>

            {/* <CardActions className={classes.cardAction}>
              <Tooltip
                title="Compare (Coming Soon)"
                aria-label="Add to Compare"
                placement="top"
              >
                <IconButton
                  aria-label="Add to Compare"
                  className={classes.compareIcon}
                >
                  <CompareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Inquiry (Coming Soon)"
                aria-label="Add to Inquiry"
                placement="top"
              >
                <IconButton aria-label="Add to Inquiry">
                  <AddCommentIcon />
                </IconButton>
              </Tooltip>
            </CardActions> */}
          </Card>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
          >
            <Tab label="Specification" component="h2" />
            <Tab label="Features" component="h2" />
            <Tab
              label={
                <Badge
                  color="primary"
                  className={classes.tab}
                  badgeContent={
                    productInfo["downloads"] === null
                      ? 1
                      : product.download
                      ? 2 + product.download.split(",").length
                      : 2
                  }
                >
                  Downloads
                </Badge>
              }
              component="h2"
            />
          </Tabs>
          {value === 0 && (
            <TabContainer>
              <Table className={classes.table}>
                <Hidden smDown>
                  <colgroup>
                    <col style={{ width: "45%" }} />
                    <col style={{ width: "55%" }} />
                  </colgroup>
                </Hidden>
                {this.state.productCategories[productSpec["ProductType"]].map(
                  (category, i) => (
                    <React.Fragment key={i}>
                      <TableHead className={classes.head}>
                        <TableRow>
                          <TableCell align="left" className={classes.headCell}>
                            {category.category}
                          </TableCell>
                          <TableCell align="left" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {category.rows.map((row, index) => (
                          <TableRow className={classes.row} key={index}>
                            <TableCell component="th" scope="row">
                              {row}
                            </TableCell>
                            <TableCell align="left">
                              {this.renderSpec(productSpec[row])}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </React.Fragment>
                  )
                )}
              </Table>
              <br />
              <Table className={classes.table}>
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell align="left" className={classes.headCell}>
                      OUTPUT SOCKETS (Inverters and Chargers only)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" style={{ padding: 0 }}>
                      <img
                        src={ImgSockets}
                        style={{ width: "100%", padding: 8 }}
                        alt="Available Output Sockets for Power Inverters and Battery Chargers"
                        title="Output Sockets"
                        loading="lazy"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              {productfeatures ? (
                <Grid item xs={12}>
                  <div>
                    <List dense={true}>
                      {productfeatures.map((feature, index) => (
                        <ListItem className={classes.listItem} key={index}>
                          <ListItemIcon>
                            <CheckCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={feature}
                            style={{ whiteSpace: "pre-line" }}
                            component="h3"
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
              ) : (
                <SnackbarContent
                  className={classes.snackbar}
                  message={"Coming Soon"}
                />
              )}
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer>
              <Grid item xs={12}>
                <div>
                  <List dense={true}>
                    {productInfo["downloads"] && (
                      <Link
                        target="_blank"
                        href={productInfo["downloads"]}
                        color="inherit"
                      >
                        <ListItem dense button>
                          <PictureAsPdfIcon />
                          <ListItemText
                            primary={
                              productSpec["ProductSubtype"] +
                              " Series Datasheet"
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="download datasheet"
                            >
                              <GetAppIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Link>
                    )}

                    {product.download &&
                      downloads.map((itemUrl, key) => (
                        <Link
                          target="_blank"
                          href={itemUrl}
                          color="inherit"
                          key={key}
                        >
                          <ListItem dense button>
                            <PictureAsPdfIcon />
                            <ListItemText
                              primary={
                                itemUrl.includes("48v-series")
                                  ? productSpec["ProductSubtype"] +
                                    " 48V Series Datasheet"
                                  : itemUrl.includes("sn-lcd-series")
                                  ? "SN-LCD Series Datasheet"
                                  : itemUrl.includes("sn-c-lcd-series")
                                  ? "SN-C LCD Series Datasheet"
                                  : itemUrl.includes("sn-c-bt-series")
                                  ? "SN-C BT Series Datasheet"
                                  : itemUrl.includes("ad-lfp-series")
                                  ? "AD-LiFePO4 Series Datasheet"
                                  : itemUrl.includes("ls-h-series")
                                  ? "LS-H Series Datasheet"
                                  : itemUrl.includes("brochures")
                                  ? product.title + " Digital Brochure"
                                  : ""
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label={
                                  "download " + itemUrl.includes("brochures")
                                    ? "brochures"
                                    : "datasheet"
                                }
                              >
                                <GetAppIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      ))}

                    {/* SP 48V Datasheet */}
                    {/* {product.download &&
                      product.download.includes("48v-series") && (
                        <Link
                          target="_blank"
                          href={product.download}
                          color="inherit"
                        >
                          <ListItem dense button>
                            <PictureAsPdfIcon />
                            <ListItemText
                              primary={
                                productSpec["ProductSubtype"] +
                                " 48V Series Datasheet"
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="download 48v datasheet"
                              >
                                <GetAppIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      )} */}

                    {/* SN LCD Datasheet */}
                    {/* {product.download &&
                      product.download.includes("sn-lcd-series") && (
                        <Link
                          target="_blank"
                          href={product.download}
                          color="inherit"
                        >
                          <ListItem dense button>
                            <PictureAsPdfIcon />
                            <ListItemText
                              primary={
                                productSpec["ProductSubtype"] +
                                " LCD Series Datasheet"
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="download SN-LCD series datasheet"
                              >
                                <GetAppIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      )} */}

                    {/* SN-BT, CN-150 Brochures */}
                    {/* {product.download &&
                      product.download.includes("brochures") && (
                        <Link
                          target="_blank"
                          href={product.download}
                          color="inherit"
                        >
                          <ListItem dense button>
                            <PictureAsPdfIcon />
                            <ListItemText
                              primary={product.title + " Digital Brochure"}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="download brochure"
                              >
                                <GetAppIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      )} */}

                    <Link target="_blank" href={product.image} color="inherit">
                      <ListItem dense button>
                        <ImageIcon />
                        <ListItemText
                          primary={product.title + " Product Image"}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="download image">
                            <GetAppIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                  </List>
                </div>
              </Grid>
            </TabContainer>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withTheme(theme)(withStyles(styles)(ProductSpec));
